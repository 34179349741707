.background-image {
  width: 100%;
  height: 100vh;
  inset: 0px;
  z-index: -1;
  position: absolute;
  pointer-events: none;
}

.image-test {
  background-color: rgb(140, 0, 255);
  height: 180px;
  width: 320x;
  z-index: 20;
  display: "flex";
  overflow: "visible";
  align-items:"right";
  transition:0.5s;
}
.smallbox {
  cursor: pointer;
}

.image-test:hover {
  background-color: rgba(166, 0, 255, 0);
  transition:0.5s;
}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}