body {
    /* background: url('/BGKITY.png') fixed; */
    background-size: cover;
 
    min-width: 100vh;
    min-height: 100vh;
}

.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: monospace;
}

.fade-in {
    animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.mainBox {
    /* border-style: solid;
    border-width: 100px 200px 100px 200px;
    border-color: #ffc3ed #ffd4f2 #ffc3ed #ffd4f2; */
    margin-top: 600px;
    font-family: monospace;
    display: flex;
    position: absolute;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.300);
    background: #ff67ae;
    border-radius: 10px;
    min-height: 300px;
    min-width: 500px;
    width: auto;
}

.toolbar {
    display:flex;
    bottom:0;
    width: 100%;
    position:absolute;
    background-color: #ff3392;
    min-height: 60px;
    min-width: 100%;
}

.image {
    background-position: center;
}

.textContainer {
    font-family: monospace;
    display: flex;
    flex-direction: column;
    color: white;
    text-shadow: 1px 1px #ff3392, 0 0px 15px rgb(255, 62, 220) ;
    font-size: 28px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}


.outlineButton {
    font-family: monospace;
    padding: 4px;
    text-shadow: 1px 1px #ff3392, 0 0px 15px rgb(255, 62, 220) ;
    color: white;
    border-radius: 15px;
    background-color: #ff33926f;
    border: 2px solid #ff3392;
    transition: 0.4s;
    text-align: center;
    font-size: 25px;
    width: auto;
    user-select: none;
    margin-top: 20px;
    flex-grow: 1;
}

.money {
    color: #ff00bb;
    text-shadow: 0px 0px 20px #ff005d;
    animation: float 2s forwards linear;
    font-size: 30px;
    position: absolute;
    min-width: 200px;
    margin: 0px;
    user-select: none;
    pointer-events: none;
}

@keyframes float {
    0% {
        opacity: 1;
    }

    100% {
        top: 50%;
        opacity: 0;
    }
}

.outlineButton:hover {
    padding: 4px;
    color: #ff98fa ;
    background-color: #ff3392;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 0 0px 15px #ff3392;
}

.newPrompt {
    position: absolute;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.300);
    background: #ff67ae;
    border-radius: 10px;
    min-height: 200px;
    min-width: 650px;
    width: auto;
    margin-top: 600px;
}

.bubble {
    width: 400px;
    margin: 25px auto;
    background: #ff98fa;
    padding: 20px;
    text-align: center;
    font-weight: 900;
    position:relative;
    color: white;
    text-shadow: 1px 1px #ff3392, 0 0px 15px rgb(255, 62, 220);
    font-size: 20px;
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.138);
  }

.headerPrompt {
    color: #000000 !important;
    text-shadow: 1px 1px #ff3392, 0 0px 15px rgb(255, 62, 220);
}

  .sb3:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #ff98fa;
    border-top: 10px solid #ff98fa;
    border-bottom: 10px solid transparent;
    left: -19px;
    top: 10px;
  }

.newPromptButtonContainer {
    display:flex;
    row-gap: 30px;
    justify-content: center;
    column-gap: 40px;
    width: 100%;
}


.newPromptContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:200px;
}

.newPromptContent {
    display:flex;
    flex-direction: column;
}

.newPromptAvatar {
    box-shadow: 0 0px 15px rgba(0, 0, 0, 0.138);
    border: 10px solid #ff98fa; 
    max-height: 150px;
    max-width: 150px;
    background: url("https://arjun.needs-to-s.top/5vp8DpK.png");
    margin:25px;
}


.fadeOut {
    opacity: 0;
    transition: opacity 0.5s ease;
}